import { Component, Vue } from 'vue-property-decorator'
import { Group, User } from '@/Models'
import http from '@/services'
import Search from '@/components/Search/index.vue'
import UserForm from '@/components/UsersForm/UsersForm.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import modalComponent from '@/components/ModalComponent/ModalComponent.vue'

@Component({
  components: {
    Search,
    UserForm,
    ModalDelete,
    modalComponent,
  },
})
export default class UsersView extends Vue {
  action = 'list'
  delete_modal_active = false
  objEdit: User = new User()
  headers: Array<any> = [
    {
      field: 'identity_card',
      label: this.$t('users.id'),
      width: '40',
    },
    {
      field: 'first_name',
      label: this.$t('users.Name'),
      width: '40',
    },
    {
      field: 'middle_name',
      label: this.$t('users.Middle_name'),
    },
    {
      field: 'last_name',
      label: this.$t('users.Last_name'),
    },
    {
      field: 'date_created',
      label: this.$t('users.Date_created'),
    },
  ]
  data: Array<User> = []
  userRoles: Array<any> = []

  mounted() {
    this.getUsers()
  }

  filterItems(items: any) {
    this.data = items
  }

  add() {
    this.action = 'create'
  }
  edit(user: User) {
    this.objEdit = user
    //this.objEdit.permissions = this.getAction(user)
    this.action = 'edit'
  }

  getAction(user: User): null | boolean {
    let g = user?.groups.find((group: Group) => group.name == 'VACCINE_ADMIN')
    if (g) return true
    g = user?.groups.find((group: Group) => group.name == 'VACCINE_REPORT')
    if (g) return false
    return null
  }

  async getUsers() {
    try {
      const res = await http.get('panel/users/')
      if (!res.data) throw new Error(res.data)
      console.log(res)
      this.data = res.data.map((x: User) => ({
        ...x,
        date_created: new Date(x.date_created).toISOString().split('T')[0],
      }))
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  showActiveDeleteModal(user: User) {
    this.objEdit = user
    this.delete_modal_active = !this.delete_modal_active
  }

  async deleteUsers() {
    try {
      await http.delete(`panel/users/${this.objEdit.id}/`)
      this.data = this.data.filter((a: User) => a.id !== this.objEdit.id)
      this.getUsers()
      this.closeModal(false)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }

  closeForm() {
    this.action = 'list'
    this.getUsers()
  }
}
